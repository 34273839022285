import * as THREE from 'three'
import ElementoLucesTraseras from './ElementoLucesTraseras';
import ElementoLucesDelanteras from './ElementoLucesDelanteras';
import { TweenMax } from '@gsap/shockingly';



const lucesTraseras = new ElementoLucesTraseras();
const lucesDelanteras = new ElementoLucesDelanteras();

let st;

export default class Animacion1 extends THREE.Group {
  
  constructor(){

  super();

    this.add( lucesTraseras )

      
    const moveLucesTraseras = () => 
    {
        lucesTraseras.position.x = 1
        lucesTraseras.position.z = 5
        lucesDelanteras.position.y = -.12
        TweenMax.from(lucesTraseras.position, 10, {x:0, z:-5, onComplete:moveLucesTraseras})
    }

    const moveLucesDelanteras = () => 
    {
        lucesDelanteras.position.x = -2
        lucesDelanteras.position.z = 6
        lucesDelanteras.position.y = -.12
        TweenMax.from(lucesDelanteras.position, 10, {x:-4, z:-5, onComplete:moveLucesDelanteras})
    }

    moveLucesTraseras();
    
    st = setTimeout(()=>{
      moveLucesDelanteras();
      this.add( lucesDelanteras )
    },3000)

  }

  dispose(){

    clearTimeout(st);
    TweenMax.killTweensOf(lucesDelanteras);
    TweenMax.killTweensOf(lucesTraseras);
    
    this.remove( lucesTraseras )
    this.remove( lucesDelanteras )
    
  }


}