import * as THREE from 'three'

import { TweenMax } from '@gsap/shockingly';
import ElementoEsfera from './ElementoEsfera';

let st;

export default class Animacion5 extends THREE.Group {
  
  constructor(){

    super();
    this.esfera2 = new ElementoEsfera('orange')
      this.add( this.esfera2 )
      this.esfera2.position.z = (.2);
      this.esfera2.position.x = (0.4);
      this.esfera2.position.y = .4;

      this.esfera1 = new ElementoEsfera('pink')
      this.add( this.esfera1 )
      this.esfera1.position.z = (1.19);
      this.esfera1.position.x = (.3);
      this.esfera1.position.y = .3;

      this.esfera3 = new ElementoEsfera('blue')
      this.add( this.esfera3 )
      this.esfera3.position.x = (.429);
      this.esfera3.position.z = (.29);
      this.esfera3.position.y = .56;


      this.esfera4 = new ElementoEsfera('yellow')
      this.add( this.esfera4 )
      this.esfera4.position.z = (1.09);
      this.esfera4.position.x = (.4);
      this.esfera4.position.y = .3;
      

      this.esfera5 = new ElementoEsfera('red')
      this.add( this.esfera5 )
      this.esfera5.position.z = (1.09);
      this.esfera5.position.x = (.4);
      this.esfera5.position.y = .50;
  }

  dispose(){

    this.remove( this.esfera1 )
    this.remove( this.esfera2 )
    this.remove( this.esfera3 )
    this.remove( this.esfera4 )
    this.remove( this.esfera5 )
    
  }


}