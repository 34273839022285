import * as THREE from 'three'
import ElementoEsfera from './ElementoEsfera';

export default class ElementoDeslumbrar extends THREE.Group {
  constructor(){
  super();

    const esfera2 = new ElementoEsfera('white')
      this.add( esfera2 )
      esfera2.position.z = (1);
      esfera2.position.x = (0);
      esfera2.position.y = 0.1;


      const esfera1 = new ElementoEsfera('red')
      this.add( esfera1 )
      esfera1.position.z = (.59);
      esfera1.position.x = (0);
      esfera1.position.y = .0;

      const esfera3 = new ElementoEsfera('yellow')
      this.add( esfera3 )
      esfera3.position.z = (.29);
      
      esfera3.position.y = .26;
  
  }
}