import * as THREE from 'three'
import ElementoDeslumbrar from './ElementoDeslumbrar';
import { TweenMax } from '@gsap/shockingly';
import ElementoEsfera from './ElementoEsfera';
import { TweenLite } from 'gsap/gsap-core';

const lucesDeslumbrar = new ElementoDeslumbrar();
let st;

export default class Animacion2 extends THREE.Group {
  
  constructor(){

    super();


    this.esfera1 = new ElementoEsfera('red')
    this.add( this.esfera1 )
    this.esfera1.position.z = (.59)
    this.esfera1.position.x = (-0.45)
    this.esfera1.position.y = .0

    this.esfera2 = new ElementoEsfera('white')
      this.add( this.esfera2 )
      this.esfera2.position.z = (1);
      this.esfera2.position.x = (-0.40);
      this.esfera2.position.y = 0.0;


      this.esfera3 = new ElementoEsfera('yellow')
      this.add( this.esfera3 )
      this.esfera3.position.x = (-0.40)
      this.esfera3.position.y = .26;
      this.esfera3.position.z = (.29);
      
      
      TweenMax.to(this.esfera3.position, 6, {x:-0.40,y:.10,z:.89, ease:Linear.easeInOut, repeat:-1,yoyo:true});
      TweenMax.to(this.esfera3.scale, 6, {x:2.40,y:1.10,z:1, ease:Linear.easeInOut, repeat:-1,yoyo:true});

      this.position.y = .363

  }

  dispose(){

    clearTimeout(st);
    TweenMax.killTweensOf(lucesDeslumbrar);
    this.remove( lucesDeslumbrar )
    
  }


}