precision mediump float;
//varying float vRandom;

uniform vec3 uColor;
uniform sampler2D uTexture;

varying vec2 vUv;

void main()
{
    vec4 textureColor = texture2D(uTexture, vUv);
    //gl_FragColor = vec4(vRandom, 1.0, 0.0, 0.7);
    //gl_FragColor = vec4(uColor, 0.4);
    gl_FragColor = textureColor;
}