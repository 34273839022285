import * as THREE from 'three'
import vertexShader from './shaders/vertex.glsl'
import fragmentShader from './shaders/fragment.glsl'
import { TweenMax } from '@gsap/shockingly';

export default class ElementoEsfera extends THREE.Group {
    constructor( color = "yellow" ){

      super();
      
      const textureLoader = new THREE.TextureLoader();


      let flagTexture = textureLoader.load('/js/fondo/textures/amarilla.jpg');
      
      switch(color){
        
            case "red":
              flagTexture = textureLoader.load('/js/fondo/textures/roja.jpg');
              break;

            case "green":
              flagTexture = textureLoader.load('/js/fondo/textures/verde.jpg');
              break;

            case "blue":
              flagTexture = textureLoader.load('/js/fondo/textures/azul.jpg');
              break;

            case "white":
              flagTexture = textureLoader.load('/js/fondo/textures/blanca.jpg');
              break;


            case "orange":
              flagTexture = textureLoader.load('/js/fondo/textures/naranja.jpg');
              break;

            case "purple":
              flagTexture = textureLoader.load('/js/fondo/textures/morado.jpg');
              break;

            case "pink":
              flagTexture = textureLoader.load('/js/fondo/textures/rosa.jpg');
              break;

      }
      
      const geometry = new THREE.PlaneGeometry( .5, .5, 32, 32 );
      const material = new THREE.RawShaderMaterial({
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        side: THREE.DoubleSide,
        transparent:true,
        uniforms:{
          uFrequency: {value: new THREE.Vector2(10,5)},
          uTime: {value: 0},
          uColor: {value: new THREE.Color("#0FFFF0")},
          uTexture: {value: flagTexture}
        },
        blending: THREE.AdditiveBlending,
        depthWrite: false
      });


      TweenMax.to(material.uniforms.uTime, 1000, {value:1000, ease:"none"})

      const mesh = new THREE.Mesh(geometry, material)
      this.add(mesh);
    
      const count = geometry.attributes.position.count;
      const randoms =  new Float32Array( count );
      
      for(let i = 0; i < count; i++ )
      {
        randoms[i] = Math.random();
      }
      
      geometry.setAttribute('aRandom', new THREE.BufferAttribute( randoms, 1 ) );

     
    }
  
  
  }
  