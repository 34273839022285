import * as THREE from 'three'

export default class EscenaFondo {
    constructor( querySelector ){
      
      this.scene = new THREE.Scene();
      this.camera = new THREE.PerspectiveCamera();
      this.camera.position.set(0, 0, 2);
  
      
      //this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, -1, 1);
      

        this.renderer = new THREE.WebGLRenderer({
            canvas: document.querySelector(querySelector)

        })


      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.setClearColor("#181818")
      this.scene.add(this.camera);
      this.animate();
    }
  
    add( element ) {
      this.scene.add( element );
    }

    remove( element ) {
      this.scene.remove( element );
    }
    
    animate = () => {
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    }
  
  
  }
  