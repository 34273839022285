import * as THREE from 'three'

import { TweenMax } from '@gsap/shockingly';
import ElementoEsfera from './ElementoEsfera';


let st;

export default class Animacion4 extends THREE.Group {
  
  constructor(){

    super();

      
      

      this.esfera2 = new ElementoEsfera('blue')
      
      this.esfera2.position.z = (.190);
      this.esfera2.position.x = (0);
      this.esfera2.position.y = 0;


      this.esfera1 = new ElementoEsfera('orange')
      this.esfera1.position.z = (.29);
      this.esfera1.position.x = (0);
      this.esfera1.position.y = .0;

      this.esfera3 = new ElementoEsfera('pink')
      this.esfera3.position.x = (.119);
      this.esfera3.position.z = (.419);
      this.esfera3.position.y = .016;

      this.grupo1 = new THREE.Group();
      this.add( this.grupo1 )

      this.grupo1.add( this.esfera1 )
      this.grupo1.add( this.esfera2 )
      this.grupo1.add( this.esfera3 )

      this.grupo1.position.y = .6

      TweenMax.to(this.grupo1.scale, 12, {x:1.4,y:1.6,z:1, ease:Linear.easeInOut, repeat:-1,yoyo:true});
      TweenMax.to(this.grupo1.rotation, 15, {z:Math.PI*2, ease:Linear.easeInOut, repeat:-1});
      TweenMax.to(this.esfera3.position, 10.5, {x:-.1, ease:Linear.easeNone, repeat:-1,yoyo:true});
      TweenMax.to(this.esfera2.position, 11.6, {x:-.2, ease:Linear.easeNone, repeat:-1,yoyo:true});
  }

  dispose(){

    
    
    
  }


}