import EscenaFondo from './fondo/EscenaFondo'
import Animacion1 from './fondo/Animacion1';
import Animacion2 from './fondo/Animacion2';
import Animacion3 from './fondo/Animacion3';
import Animacion4 from './fondo/Animacion4';
import Animacion5 from './fondo/Animacion5';
import Animacion6 from './fondo/Animacion6';


let currentAnimation = {}
let currentAnimationInt = 0
const escena = new EscenaFondo('canvas.webgl')


window.setAnimationFondo = ( anim = 0 ) =>{

    if( currentAnimationInt === 0){
        
        currentAnimationInt = anim
        
        switch (anim){

            case 1:
                currentAnimation = new Animacion1()
                break

            case 2:
                currentAnimation = new Animacion2()
                break

            case 3:
                currentAnimation = new Animacion3()
                break

            case 4:
                currentAnimation = new Animacion4()
                break

            case 5:
                currentAnimation = new Animacion5()
                break

            case 6:
                currentAnimation = new Animacion6()
                break
        }

        escena.add(currentAnimation)



    }else{
        //console.log("Ya hay una animación activa")
        currentAnimation.dispose()
        escena.remove(currentAnimation)
        currentAnimationInt = 0
        window.setAnimationFondo(anim)
    }
    
}

window.setAnimationFondo(1);


addEventListener("mousemove", (event) => {});

onmousemove = (event) => {
    //console.log(event.offsetX,event.offsetY)
    //escena.camera.lookAt( event.clientX*.0001,event.clientY*.0001,0);
};
