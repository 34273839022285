import * as THREE from 'three'
import vertexShader from './shaders/vertex.glsl'
import fragmentShader from './shaders/fragment.glsl'
import { TweenMax } from '@gsap/shockingly';
import ElementoEsfera from './ElementoEsfera';

export default class ElementoLucesTraseras extends THREE.Group {
  constructor(){
  super();
    
  const esfera = new ElementoEsfera('red')
  this.add( esfera )
  esfera.color = 'red';
  esfera.position.z = (-5.2);
  esfera.position.x = (.5);

    const esfera2 = new ElementoEsfera('red')
      this.add( esfera2 )
      esfera2.color = 'red';
      esfera2.position.z = (-5.2);
      esfera2.position.x = (1);

  
  }
}