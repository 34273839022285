import * as THREE from 'three'
import ElementoEsfera from './ElementoEsfera';

export default class ElementoLucesDelanteras extends THREE.Group {
  constructor(){
  super();
    
  const esfera = new ElementoEsfera('white')
  this.add( esfera )
  esfera.position.z = (-5.2);
  esfera.position.x = (.5);

    const esfera2 = new ElementoEsfera('white')
      this.add( esfera2 )
      esfera2.position.z = (-5.2);
      esfera2.position.x = (1);

  
  }
}