import * as THREE from 'three'
import ElementoDeslumbrar from './ElementoDeslumbrar';
import { TweenMax } from '@gsap/shockingly';
import ElementoEsfera from './ElementoEsfera';

const lucesDeslumbrar = new ElementoDeslumbrar();
let st;

export default class Animacion3 extends THREE.Group {
  
  constructor(){

    super();

      this.esfera1 = new ElementoEsfera('red')
      this.esfera1.position.z = (.59);
      this.esfera1.position.x = -(.70);
      this.esfera1.position.y = .0;
      this.esfera1.scale.y = 3;

      this.esfera4 = new ElementoEsfera('pink')
      this.esfera4.position.z = (.59);
      this.esfera4.position.x = -(.70);
      this.esfera4.position.y = .0;
      this.esfera4.scale.y = 2.4;

    this.grupo1 = new THREE.Group();

    this.esfera2 = new ElementoEsfera('blue')
      this.esfera2.position.z = (1);
      this.esfera2.scale.y = 1.4;
      this.esfera2.scale.x = 1.4;


      this.esfera3 = new ElementoEsfera('yellow')
      this.esfera3.position.x = (-.29);
      this.esfera3.position.z = (.29);
      this.esfera3.position.y = .26;

      this.add( this.esfera4 )
      this.grupo1.add(this.esfera2);
      this.grupo1.add(this.esfera3);
      this.add( this.grupo1 )
      this.add( this.esfera1 )
      

      this.grupo1.position.y = -1.2
      this.grupo1.position.x = -.4
      TweenMax.to(this.grupo1.position, 12, {x:-.7,y:1.2,z:.1, ease:Linear.easeNone, repeat:-1,yoyo:true});
      
  }

  dispose(){

    clearTimeout(st);
    TweenMax.killTweensOf(lucesDeslumbrar);
    this.remove( lucesDeslumbrar )
    
  }


}